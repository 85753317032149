<template>
  <iq-card>
    <template v-slot:headerTitle>
      <h4 class="card-title">{{ $t("CustomerImport.Title") }}</h4>
    </template>
    <template v-slot:body>
      <b-alert v-if="!isCompleted" show variant="info">
        <b style="margin-inline: auto;">{{ $t("CustomerImport.InfoForUser") }}</b>
      </b-alert>
      <div v-if="!isCompleted">
        <b-row style="display: flex; justify-content: center;">
          <b-col md="4" class="text-left">
            <label class="form-control-label"> {{ $t("CustomerImport.SelectExcelFile") }}</label>
          </b-col>
          <b-col md="4">
            <div class="custom-file" style="text-align:left">
              <input type="file" class="custom-file-input" @change="handleFileUpload">
              <label class="custom-file-label" for="customFile">{{ $t("SelectFile") }}</label>
              <label v-if="fileName" class="form-control-label kt-font-info">
                {{ fileName }}
              </label>
              <label v-if="invalidFileMessage" class="form-control-label kt-font-danger">
                {{ invalidFileMessage }}
              </label>
            </div>
          </b-col>
        </b-row>
        <br />
        <b-row style="display: flex; justify-content: center;">
          <b-col md="4" class="text-left">
            <label> {{$t("CustomerImport.NameSurnameInSeperateColumns")}} </label>
          </b-col>
          <b-col md="4">
            <b-form-checkbox switch size="lg" v-model="model.nameSurnameInSeperateColumns"></b-form-checkbox>
          </b-col>
        </b-row>
        <br />
        <b-row style="display: flex; justify-content: center;">
          <b-col md="4" class="text-left">
            <label> {{ $t("CustomerImport.SelectedGenderIfNotExists") }} </label>
          </b-col>
          <b-col md="4">
            <v-select transition="" v-model="model.selectedGenderId"
                      :reduce="s => s.id" label="name"
                      :clearable="false"
                      :options="genderList">
            <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
          </b-col>
        </b-row>
        <br />
        <b-row style="display: flex; justify-content: center;">
          <b-col md="4" class="text-left">
            <label> {{ $t("CustomerImport.SelectedCtIfNotExists") }} </label>
          </b-col>
          <b-col md="4">
            <v-select transition="" v-model="model.selectedCustomerTypeId"
                      :reduce="s => s.id" label="name"
                      :clearable="false"
                      :options="customerTypeList">
            <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
          </b-col>
        </b-row>
        <br />
        <div v-if="Object.keys(headers).length === 0 && headers.constructor === Object">
          <div class='modal-footer' v-if="fileName && fileName.length > 0">
            <b-button @click="getHeaderColumns()" variant="success">
              {{$t('Continue')}}
            </b-button>
          </div>
        </div>

        <div v-else>
          <b-alert show variant="primary">
            <b style="margin-inline: auto;">{{$t('CustomerImport.InfoMessage')}}</b>
          </b-alert>
          <header-columns v-for="(column,index) in headers.ourColumns" @changeColumnMapping="changeColumnMapping"
                          :our-column="column" :data-columns="headers.dataColumns" :key="index" :invalid="column.dataName == 'NameSurname' || column.dataName =='Name' || column.dataName == 'Surname' ? isValidName(): column.dataName == 'Phone' ? isValidPhone() : true" />
          <modal-footer-button :set-is-submitting="isSubmitting" v-if="!isProcessStarted" @submit="importFile"> </modal-footer-button>
        </div>
      </div>

      <div v-else>
        <b-alert show variant="success">
          {{ $t("CustomerImport.AddedCount") }}: {{countAdded}}
        </b-alert>
        <b-alert show variant="info">
          {{ $t("CustomerImport.DuplicateCount") }}: {{countDuplicate}}
        </b-alert>
        <b-alert show variant="danger">
          {{ $t("CustomerImport.ErrorCount") }}: {{countError}}
        </b-alert>

        <div class='modal-footer'>
          <b-button @click="openCustomerList()" variant="success">
            {{$t('CustomerList')}}
          </b-button>
        </div>
      </div>
    </template>
  </iq-card>
</template>
<script>
  import HeaderColumns from '../../../components/customer/HeaderColumns';
  import ModalFooterButton from '../../../components/shared/ModalFooterButton';

  import customerServices from '../../../services/customer';
  export default {
    name: 'CustomerImport',
    components: {
      HeaderColumns,
      ModalFooterButton
    },
    data() {
      return {
        model: {
          nameSurnameInSeperateColumns: false,
          selectedGenderId: 3,
          selectedCustomerTypeId: '',
        },
        tryIsSubmitting: false,
        fileName: '',
        file: {},
        invalidFileMessage: '',

        genderList: [],
        customerTypeList: [],

        headers: {},
        selectedDataColumns: [],

        isCompleted: false,
        isProcessStarted: false,
        isSubmitting: false
      }
    },
    methods: {
      getGenderList() {
        customerServices.getGenders().then((response) => {
          if (response) {
            this.genderList = response;
          }
        })
      },
      openCustomerList() {
        this.$router.push({ name: 'customer.list' })
      },
      changeColumnMapping(val, index) {
        this.selectedDataColumns[index - 1] = val;
      },
      getCustomerTypeList() {
        customerServices.getCustomerTypeList().then((response) => {
          if (response) {
            this.customerTypeList = response;
          }
        }).finally(() => {
          if (this.customerTypeList)
            this.model.selectedCustomerTypeId = this.customerTypeList[0].id;
        });
      },
      handleFileUpload(event) {
        this.fileName = '';
        this.invalidFileMessage = '';
        this.file = event.target.files[0];
        if (this.file) {
          const validFile = this.file.name.includes('.xlsx')
          if (validFile)
            this.fileName = this.file.name;
          else
            this.invalidFileMessage = this.$t('CustomerImport.InvalidFileMessage');
        }
      },
      getHeaderColumns() {
        let form = new FormData();
        form.append('file', this.file);
        form.append('nameSurnameInSeperateColumns', this.model.nameSurnameInSeperateColumns);
        customerServices.postCustomerImport(form).then((response) => {
          if (response) {
            this.headers = response;
            this.isCompleted = false;
          }
        });
      },
      isValidName: function () {
        let valid = true;
        let nameColumn = this.selectedDataColumns?.filter(s => s != null && (s.key === "NameSurname" || s.key === "Name" || s.key === "Surname"));
        if (nameColumn.length == 0 && this.tryIsSubmitting) {
          valid = false;
        }
        nameColumn.forEach(function (item) {
          if (item.key === "NameSurname" && item.value.trim() === "") {
            valid = false;
          }
          else if (item.key === "Name" && item.value.trim() === "") {
            valid = false;
          }
          else if (item.key === "Surname" && item.value.trim() === "") {
            valid = false;
          }
        });
        return valid;
      },
      isValidPhone: function () {
        let valid = true;
        let nameColumn = this.selectedDataColumns?.filter(s => s != null && (s.key === "Phone"));

        if (nameColumn.length == 0 && this.tryIsSubmitting) {
          valid = false;
        }

        this.selectedDataColumns.filter(s => s.key == "Phone").forEach(function (item) {
          if (item.key === "Phone" && item.value.trim() === "") {
            valid = false;
          }
        });
        return valid;
      },
      importFile() {
        this.tryIsSubmitting = true;
        let isValid = true;
        this.selectedDataColumns?.filter(z => z != null).forEach(function (item) {
          if (item.key === "NameSurname" && item.value.trim() === "") {
            isValid = false;
          }
          if (item.key === "Name" && item.value.trim() === "") {
            isValid = false;
          }
          if (item.key === "Surname" && item.value.trim() === "") {
            isValid = false;
          }
          if (item.key === "Phone" && item.value.trim() === "") {
            isValid = false;
          }
        });
        if (!isValid) {
          this.$toastr.error(this.$t('ValidationMessage.EmptyAndValidateError'));
        }
        if (isValid) {
          let form = new FormData();
          form.append('file', this.file);
          form.append('nameSurnameInSeperateColumns', this.model.nameSurnameInSeperateColumns);
          form.append('defaultGenderId', this.model.selectedGenderId);
          form.append('defaultCustomerTypeId', this.model.selectedCustomerTypeId);
          form.append('columnMapping', JSON.stringify(this.selectedDataColumns?.filter(z => z != null)));

          this.isProcessStarted = true;
          this.$toastr.info(this.$t('CustomerImport.WarningMessage'));
          this.isSubmitting = true;

          customerServices.postExcel(form).then((response) => {
            this.isSubmitting = false;
            if (response.success) {
              this.$toastr.success(this.$t(response.message));
              this.isCompleted = true;
              this.isProcessStarted = false;
              this.countAdded = response.countAdded;
              this.countDuplicate = response.countDuplicate;
              this.countError = response.countError;
            } else {
              this.$toastr.error(this.$t(response.message));
            }
          }).finally(() => { this.isSubmitting = false; });
        }
      },
    },
    computed: {

    },
    mounted() {
      this.getGenderList();
      this.getCustomerTypeList();
    }
  }
</script>
