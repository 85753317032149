<template>
  <div class="row" style="display:flex; justify-content:center;">
    <div class="form-group col-lg-4 col-md-6">
      <label class="form-control-label"> {{$t(column.displayName)}}</label>
    </div>
    <div class="form-group col-lg-4 col-md-6">
      <v-select transition="" v-model="selectedColumn"
                :reduce="s => s" label="displayName"
                :selectable="s => !s.isSelected"
                :options="dataColumnList">
      <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
      <b-form-invalid-feedback :state="invalid">{{ $t('ValidationMessage.CannotBeEmpty') }}</b-form-invalid-feedback>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      ourColumn: Object,
      dataColumns: Array,
      invalid: { typeof: Boolean, default: true }
    },
    data() {
      return {
        selectedColumn: '',
        oldColumn: {}, // Seçileni ve kaldırılmış seçimi tutmak amacıyla kullanılıyor. Disabled özelliği bunun üzerinden değiştiriliyor.
        columnMapping: { key: '', value: '' }
      }
    },
    methods: {
      setMappingColumn() {
        let $this = this;

        $this.columnMapping = {
          key: $this.ourColumn.dataName,
          value: $this.oldColumn.dataName ?? ''
        };
        this.$emit('changeColumnMapping', $this.columnMapping, $this.ourColumn.index);
      }
    },
    watch: {
      dataColumnList: {
        handler: function (list) {
          if (list) {
            let $this = this;
            let selected = list.find(z => z.displayName.toLowerCase() == $this.$t($this.column.displayName).toLowerCase());
            if (selected) {
              $this.oldColumn = selected;
              $this.oldColumn.isSelected = true;
              $this.selectedColumn = selected;
            }

            $this.setMappingColumn();
          }
        },
        immediate: true
      },
      selectedColumn: {
        handler: function (selected) {

          if (selected == null) {
            this.oldColumn.isSelected = false;
            if (this.ourColumn != null) {
              this.ourColumn.isSelected = false;
              this.columnMapping.value = '';
              // selectedDataColumns içindeki value değerini sıfırla
              let selectedDataColumn = this.$parent.selectedDataColumns.find(item => item.index === (this.ourColumn.index - 1));
              if (selectedDataColumn) {
                this.$set(selectedDataColumn, 'value', '');
              }
            }
          } else if (selected) {
            selected.isSelected = true;
            if (Object.keys(this.oldColumn).length === 0) {
              this.oldColumn = selected;
              this.oldColumn.isSelected = true;
            }
            else if (this.oldColumn.displayName != selected.displayName) {
              this.oldColumn.isSelected = false;
              this.oldColumn = selected;
            }
            if (this.ourColumn != null) {
              this.ourColumn.isSelected = true;
            }
            this.columnMapping.value = this.oldColumn.isSelected ? this.oldColumn.dataName : '';
            // selectedDataColumns içindeki value değerini güncelle
            let selectedDataColumn = this.$parent.selectedDataColumns.find(item => item.index === (this.ourColumn.index - 1));
            if (selectedDataColumn) {
              this.$set(selectedDataColumn, 'value', '');
            }
          } else {
            if (this.ourColumn != null) {
              this.ourColumn.isSelected = false;
              this.columnMapping.value = '';
              // selectedDataColumns içindeki value değerini sıfırla
              let selectedDataColumn = this.$parent.selectedDataColumns[this.ourColumn.index - 1];
              if (selectedDataColumn) {
                selectedDataColumn.value = '';
              }
            }
          }

          this.setMappingColumn();
        },
        deep: true
      }
    },
    computed: {
      column: function () {
        return this.ourColumn;
      },
      dataColumnList: function () {
        return this.dataColumns;
      }
    }
  }
</script>
